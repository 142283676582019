.container {
  padding: var(--space-sm);
  border: 1px solid var(--color-border);

  display: flex;
  flex-flow: column nowrap;
  gap: var(--space-md, 1rem);
}

.error {
  background-color: var(--color-bg-error);
  color: var(--color-text-error);
}

.warning {
  background-color: var(--color-bg-warning);
  color: var(--color-text-error);
}
.success {
  background-color: var(--color-bg-success);
  color: var(--color-text-error);
}
.neural {
  background-color: var(--color-bg-neutral);
  color: var(--color-text);
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--space-md, 1rem);
}
